import * as React from "react"
import styled from "styled-components"
import Header from "../layout/header"
import Meta from "../../utilities/seo"
import SectionContainer from "../../microsites/investor-relations/section-container"
import HeroBkg from "../../microsites/investor-relations/images/dfy-RlIgBOphKxU-unsplash.jpg"
import Footer from "../layout/footer"

const Splash = styled.div`
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  h1 {
    font-family: var(--xn-font-body);
    font-weight: bold;
    font-size: 3rem;
    letter-spacing: -3px;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      rgba(242, 242, 242, 0),
      rgba(242, 242, 242, 1)
    );

    @media (prefers-color-scheme: dark) {
      background-image: linear-gradient(
        rgba(12, 16, 21, 0),
        rgba(12, 16, 21, 1)
      );
    }
  }

  .title {
    span {
      display: block;
      text-align: center;
    }
  }
`

const Hero = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 90%;

  img {
    width: 100%;
    height: 100%;
    transform: scale3d(-1, 1, 1);
    object-fit: cover;
    opacity: 40%;
  }
`

const Box = styled.main`
  background-color: #f2f2f2;
  min-height: 100vh;

  @media (prefers-color-scheme: dark) {
    background-color: #0c1015;
  }

  .collection {
    padding: 1.5rem;
    border-radius: 0.75rem;
    margin-bottom: 3rem;
    background: #75cbba17;
  }
`

const Ele = ({ pageTitle, children, pageSubtitle }) => {
  return (
    <>
      <Meta
        title={pageTitle}
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/c_fill,h_1080,w_1920/v1674160220/noah-windler-gQI8BOaL69o-unsplash_oth7lp.jpg",
          height: "1920",
          width: "1080",
        }}
      />
      <Header />
      <Box>
        <Splash>
          <div className="title">
            <h1>{pageTitle}</h1>
            <span>{pageSubtitle}</span>
          </div>
          <Hero>
            <img src={HeroBkg} alt="" />
          </Hero>
        </Splash>
        <SectionContainer>
          <div className="page-content">{children}</div>
        </SectionContainer>
      </Box>

      <Footer />
    </>
  )
}
export default Ele
