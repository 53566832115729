import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  width: 60%;
  margin: 0 auto;

  @media (max-width: 766px) {
    width: 100%;
    padding: 0 1.5rem;
  }

  .page-content {
    .inner {
      width: 90%;
      margin: 0 auto;
    }
    section {
      padding-bottom: 3.25rem;
    }

    h2 {
      padding-bottom: 1.25rem;
      font-size: 1.125rem;
    }

    h3 {
      font-weight: bold;
      padding-bottom: 0.5rem;
    }

    p,
    li {
      padding-bottom: 1.5rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    ul,
    ol {
      margin: 2rem auto;
      width: 90%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      list-style: decimal outside;
    }
  }
`

const Ele = ({ children }) => {
  return <Box>{children}</Box>
}
export default Ele
