import * as React from "react"
import PageTemplate from "../components/layout/page-template"

const Ele = () => {
  return (
    <PageTemplate
      pageTitle="Privacy Policy"
      pageSubtitle="Last Update: April 11, 2024"
    >
      <section>
        <h2>Ensuring Your Privacy</h2>
        <p>
          Earning your trust is XENO’s top priority. The privacy of your
          information is essential to earning and keeping that trust. All the
          decision we make about how we gather, store, and use any information
          we obtain from you is driven by this principle. Your personal
          information will never be traded, rented, or sold to anyone.
        </p>
      </section>
      <section>
        <h2>Introduction</h2>
        <p>
          MYXENO Investment Management (“XENO”, “we”, “us” and/or “our”)
          operates an Automated Investment Management Platform (“Platform”). The
          Platform is available on our website, mobile application, and USSD.
          This Privacy Policy details how XENO handles your personal information
          whenever you use our Platform. Throughout this agreement, a Visitor
          refers to a person who uses our Platform to assess our Platform, or
          for purpose of gaining information. On the other hand, a Client is a
          person who signs our Investment Management Agreement and sets up an
          investment account with us. This Privacy Policy governs your use of
          our Platform.
        </p>
      </section>
      <section>
        <h2>Collection of Information</h2>
        <p>
          The kind of information we collect depends on whether you are a
          Visitor or a Client. We collect Personal Information from you when you
          voluntarily provide information to us. Examples of instances when we
          collect Personal Information include when you answer questions on our
          website to select your goal and determine your risk tolerance and the
          recommended investment portfolio if you were to become a Client.
        </p>
        <p>
          The information collected includes some information that you
          voluntarily provide such as; age. We may also collect your name, email
          address, and/or telephone number whenever you choose to engage with us
          through our website, mobile application, USSD, email, text message or
          telephone. This information is collected whenever you are in the
          process of registering to open an investment account when you contact
          us through the landing page or inside your account when you become a
          Client. At whatever point XENO collects Personal Information, we make
          sure to link to this Privacy Policy and other relevant policies, like
          our Terms of Use. Specifically, we collect this information from
          Visitors and Clients;{" "}
        </p>
        <ul>
          <li>
            <span className="bold">Visitor Information collected. </span>
            <p>
              To use our Platform, we may collect certain personal information
              such as name, address, telephone number, and email address. We
              also collect some information that you voluntarily provide such as
              age bracket, investable assets, financial goals and objectives,
              income information, and other critical financial planning
              information. We may collect your name, e-mail address and/or
              telephone number whenever you choose to engage with us through our
              website, mobile application, USSD, email or telephone.
            </p>
          </li>
          <li>
            <span className="bold">Client Information collected</span>
            <p>
              Because investment management is a highly regulated industry, if
              you choose to become a Client, besides the information we collect
              from you as a Visitor, we will also collect information that
              includes, but is not limited to: full legal name, biographical
              information such as date of birth contact information,
              identification documents citizenship and/or country of residence,
              investment objectives, net worth, and other regulatory disclosures
              that are required under by the regulators and other government
              agencies.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <h2>Partnerships</h2>
        <p>
          If we onboard you from any of our partners (or you interact with our
          Platform using integration provided by any of our partners) who
          collect your information, you consent to your user details that are
          relevant for consuming the services offered by XENO to be transferred
          to us by our Partner.
        </p>
      </section>
      <section>
        <h2>Use of Information</h2>
        <p>
          The information that is taken from both Visitors and Clients by XENO
          is stored, processed, and maintained securely. It helps us to provide
          Client support, roll out innovative products or services and operate
          within the rules and regulations set by the Capital Markets Authority,
          and other government agencies. Provision of the information is
          voluntary, however, if you decide not to provide the requisite
          information, you will most certainly not be able to use the full
          functionality of the Platform or even become a Client. Occasionally,
          we may share the information required to become a Client with third
          parties such as our custodian, trustee, and brokerage partners
          specifically to allow them to provide services to you. We may
          periodically use your Personal Information to relay certain critical
          information to you about our Platform. If you decide at any time that
          you do not wish to receive certain marketing information, use the
          “unsubscribe” steps included in every communication to cause this to
          stop. However, even when you unsubscribe from receiving marketing
          information, we will still send periodic administrative information
          such as billing and Platform notifications as well as obligatory
          regulatory notifications.
        </p>
      </section>
      <section>
        <h2>Information Sharing and Exceptions</h2>
        <p>
          XENO does not sell, rent, or trade your personal information with any
          third parties other than with your consent or as required by law.
          However, to efficiently provide our services and for our business
          operations, we may share your personal information with third parties,
          including but not limited to: consumer identification verification
          services, public accounting firms, and certain other service providers
          (including companies that perform marketing and analytics services).
        </p>
        <ul>
          <li>
            For any third parties we may share your information with, we have
            entered into agreements that require them to keep this information
            confidential. In addition, we may disclose your information to other
            third-party financial institutions, e.g., if you ask us to transfer
            your assets from XENO to such other financial institutions.{" "}
          </li>
          <li>
            We may also disclose personal information in response to service of
            legal process, such as court orders, summons, or subpoenas, or as
            permitted or required by law when we reasonably deem it is necessary
            or appropriate to investigate, prevent, or take action regarding
            illegal activities, suspected fraud (including identity theft),
            front-running or scalping, situations involving potential threats to
            the physical safety of any person, or suspected violations of our
            Privacy Policy, Terms of Use or other customer agreements.{" "}
          </li>
          <li>
            Furthermore, we may disclose personal information in specific other
            cases with your express consent. In order to provide workplace
            services to employers or plan sponsors, such as retirement savings
            plan services and recordkeeping services, we may exchange any
            information received in connection with such services with the
            employer or plan sponsor or other authorized service providers. We
            may also share information, including but not limited to account
            positions, contributions, and distributions, and with a third-party
            financial institution that serves as a trustee, or custodian bank.
          </li>
          <li>
            You may request deletion of personal information by emailing
            kenya@myxeno.com. and providing us with enough information to
            identify your account and prove that you are the owner of the
            identified account. We will endeavour to make any deletion request
            effective as soon as reasonably practicable. However, we may retain
            residual information related to your account, as well as any data
            related to your trades, in accordance with applicable laws.{" "}
          </li>
          <li>
            Under no circumstances will we share any personal information data
            unless lawfully required by courts of law, the regulators or other
            government agencies. You may decline to provide personal information
            to XENO. Declining to provide personal information may disqualify
            you from accessing XENO services and Site features that require
            certain personal information.
          </li>
        </ul>
      </section>
      <section>
        <h2>How Your Personal Information is Protected</h2>
        <p>
          XENO works diligently to protect your personal information. We deploy
          several physical and electronic safeguards to keep your information
          safe and private. We use the strongest available browser encryption,
          store all of our data on servers in secure facilities, and implement
          systematic processes and procedures for securing and storing data. For
          example, we limit access to your personal and financial information to
          only those employees with authorized access who need to know the
          information to perform their jobs, and we require third parties who
          perform services for XENO to agree to keep your information
          confidential. Moreover, if you decide to close your account or your
          account is terminated with us, we will continue to adhere to the
          privacy policies and practices outlined here. Please note that, if you
          maintain a XENO account that you access through our website, you are
          responsible for protecting and maintaining the confidentiality of your
          account and password and restricting access to your computer.
        </p>
      </section>
      <section>
        <h2>Accessing or Changing Personal Information Collected</h2>
        <p>
          Once you have registered with XENO, you can access your profile,
          review the information that is stored, and revise that information. If
          you have any problems, please contact us at kenya@myxeno.com. However,
          due to regulatory requirements, we do not delete residual copies of
          outdated information and must therefore preserve all customer data for
          a minimum of seven years.
        </p>
      </section>
      <section>
        <h2>Other Important Information</h2>
        <div className="inner">
          <section>
            <h3>Information Security</h3>
            <p>
              XENO takes commercially reasonable steps to protect your Personal
              Information from loss, misuse, and unauthorized access,
              alteration, disclosure, or destruction. No Internet, email, or
              electronic operating system that enables the transmission of data
              is ever fully secure or error-free. So, please take special care
              in deciding what information you send to us in this manner.
            </p>
          </section>
          <section>
            <h3>Advertising</h3>
            <p>We do not allow third-party advertising on our website.</p>
          </section>
          <section>
            <h3>
              Social Media and links to other websites and Apps: "Third parties"
            </h3>
            <p>
              This Privacy Policy and these terms apply only to XENO-operated
              services and applications. This Platform may contain links that
              access other websites and applications that are not operated or
              controlled by XENO. The policies described herein do not apply to
              Third Party websites or applications.
            </p>
          </section>
          <section>
            <h3>Children below the age of 8 years</h3>
            <p>
              XENO is not to be used by anyone under the age of 8 and, as such
              does not knowingly collect Personal Information from anyone under
              the age of 8. If you are under the age of 8, please do not submit
              any Personal Information to XENO. If a parent or guardian becomes
              aware that his or her child under the age of 8 has provided us
              with personally identifiable information without his or her
              consent, he or she should contact us at kenya@myxeno.com and we
              will delete such information from our files. Minors must be
              supervised by an adult in navigating our Platform.{" "}
            </p>
          </section>
          <section>
            <h3>Notification of changes</h3>
            <p>
              By using and continuing to use the XENO Platform or otherwise
              providing us with personal information (such as an email address
              or phone number) you agree to the terms and conditions of this
              privacy policy and any updates thereto. This Privacy Policy may
              change from time to time. We will post any Privacy Policy changes
              here and, if the changes are substantial, we will provide a more
              prominent notice (including, for certain services, email
              notification of Privacy Policy changes). Each version of this
              Privacy Policy will be identified at the top of the page by its
              “Last Update” date. This document constitutes XENO’s complete
              Privacy Policy for our Services. If you have questions{" "}
            </p>
          </section>
        </div>
      </section>
    </PageTemplate>
  )
}
export default Ele
